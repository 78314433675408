import { createContainer } from "unstated-next"
import { useState } from 'react'
import NotificationState from "../Notifications/index.state"
import { useApolloClient } from "@apollo/client"
import SEND_MESSAGE from './graphql/sendMessage.graphql'

const useSendMessageState = () => {
  const [message, setMessage] = useState({ author: {}, actions: [] })
  const { notifySuccess, notifyError } = NotificationState.useContainer()
  const client = useApolloClient()

  const updateMessage = (payload) => {
    setMessage((message) => ({
      ...message,
      ...payload,
      author: {
        ...message.author,
        ...(payload.author || {})
      } }))
  }

  const sendMessage = async () => {
    try {
      const { data } = await client.mutate({
        mutation: SEND_MESSAGE,
        variables: {
          input: { ...message }
        }
      })
      if (data && data.sendGlobalMessage && data.sendGlobalMessage._id) {
        notifySuccess({ title: 'Sent', text: 'Message sent!' })
      }
    } catch (err) {
      console.error(err)
      notifyError({ title: 'Error', text: 'An error has occured!' })
    }

  }

  const addAction = () => {
    let actions = [...message.actions, { name: '', link: '' }]
    updateMessage({ actions })
  }

  const updateActionAtIndex = (i, payload) => {
    let actions = [...message.actions]
    actions[i] = { ...actions[i], ...payload }
    updateMessage({ actions })
  }

  const removeActionAtIndex = (i) => {
    let actions = [...message.actions]
    actions.splice(i, 1)
    updateMessage({ actions })
  }

  return { message, sendMessage, updateMessage, addAction, updateActionAtIndex, removeActionAtIndex }
}

const SendMessageState = createContainer(useSendMessageState)

export default SendMessageState
