import React from 'react'
import MessageEditor from './components/MessageEditor'
import SendMessageState from './index.state'
import { Description, Title, Wrapper } from './index.styles'


const SendMessage = (props) => {
  return <Wrapper>
    <Title>Send Message</Title>
    <Description>
      You can use this tool to send messages to every parent product child account.
    </Description>
    <MessageEditor />
  </Wrapper>
}

SendMessage.propTypes = {

}

SendMessage.defaultProps = {

}

export default (props) => {
  return <SendMessageState.Provider>
    <SendMessage />
  </SendMessageState.Provider>
}