import { TextArea, TextInput } from "grommet"
import { FormTrash } from "grommet-icons"
import React from "react"
import styled from "styled-components"
import { colors } from "../../../../theme"
import CharacterSelector from "../../../Lessons/CreateLesson/components/CharacterSelector"
import Button from '../../../UI/Button'
import SendMessageState from "../../index.state"
import MessagePreview from "../MessagePreview"

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  > :first-child {
    width: 100%;
    max-width: calc(100% - 491px);
    margin-right: 30px;
  }
  > :nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 465px;
    min-width: 465px;
    > :first-child {
      margin-bottom: 20px;
    }
  }
`

const Form = styled.div`

`

const StyledTextArea = styled(TextArea)`
  resize: none;
`

const Field = styled.div`
  margin-bottom: 20px;
`

const FieldTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 8px;
`

const Character = styled.div`
  height: 100px;
  width: 100px;
  background-image: url("${props => props.src}");
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  background-color: #fff;
`

const Actions = styled.div`
`

const Action = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  > * { margin-right: 8px; }
  > :last-child {
    margin-right: 0;
    cursor: pointer;
  }
`

const MessageEditor = props => {
  const { message, updateMessage, sendMessage, addAction, updateActionAtIndex, removeActionAtIndex } = SendMessageState.useContainer()

  const updateLinkAtIndex = (i, value) => {
    // Trim space
    let link = value.replace(' ', '')
    // Remove URL part
    link = link.replace('https://nightzookeeper.com', '')
    link = link.replace('https://www.nightzookeeper.com', '')
    updateActionAtIndex(i, { link })
  }

  return (
    <Wrapper>
      <Form>
      <Field>
        <FieldTitle>Title</FieldTitle>
        <TextInput value={message.title} onChange={e => updateMessage({ title: e.target.value })} />
      </Field>
      <Field>
        <FieldTitle>Author Name</FieldTitle>
        <TextInput
          onChange={e => updateMessage({ author: { name: e.target.value } })}
        />
      </Field>
      <Field>
        <FieldTitle>Author Image</FieldTitle>
        {message.author && message.author.iconUrl ? (
          <Character src={message.author.iconUrl} onClick={() => updateMessage({ author: { iconUrl: null }})}></Character>
        ) : (
          <CharacterSelector
            onSelected={url => updateMessage({ author: { iconUrl: url } })}
          />
        )}
      </Field>
      <Field>
        <FieldTitle>Message</FieldTitle>
        <StyledTextArea row={2} value={message.text} onChange={(e) => updateMessage({ text: e.target.value })} />
      </Field>
      <Field>
        <FieldTitle>Actions</FieldTitle>
        <Actions>
          { (message.actions || []).map((d, i) => <Action key={i}>
            <TextInput value={d.name} placeholder='Name. e.g: Visit' onChange={e => updateActionAtIndex(i, { name: e.target.value })} />
            <TextInput value={d.link} placeholder='Link. e.g: /edu/zoo/menu' onChange={e => updateLinkAtIndex(i, e.target.value)}/>
            { /* eslint-disable-next-line */ }
            <div onClick={() => removeActionAtIndex(i)}><FormTrash color='#f00' /></div>
          </Action>)}
        </Actions>
        <Button onClick={addAction}>Add action</Button>
      </Field>
      </Form>
      <div>
        <MessagePreview {...message} />
        <Button bgColor={colors.green} onClick={() => sendMessage()}>Send</Button>
      </div>
    </Wrapper>
  )
}

MessageEditor.propTypes = {}

MessageEditor.defaultProps = {}

export default MessageEditor
