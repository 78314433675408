import Mustache from 'mustache'
import React from 'react'
import Markdown from 'react-markdown'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  > * {
    padding-left: 18px;
    padding-right: 18px;
  }
  border-radius: 11px;
  overflow: hidden;
  p {
    margin-bottom: 0;
  }
`

const Title = styled.div`
  background-color: #C067F3;
  color:#fff;
  > :first-child {
    display: flex;
    align-items: center;
  }
  p {
    margin-bottom: 0;
  }
  a {
    color: rgb(104, 0, 146);
    font-weight: bold;
    border-radius: 3px;
    text-decoration: none;
  }
  font-weight: bold;
  min-height: 55px;
  display: flex;
  align-items: center;
  font-size: 19px;
`

const Author = styled.div`
  display: flex;
  align-items: center;
  background-color: #D597F9;
  color: #000;
  padding-top: 8px;
  padding-bottom: 8px;
  > :first-child {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #fff;
    background-image: url("${props => props.icon}");
    background-size: cover;
    background-position: center;
    margin-right: 12px;
  }
  > :nth-child(2) {
    display: flex;
    align-items: center; 
    line-height: 28px;
    a {
      color: rgb(104, 0, 146);
      font-weight: bold;
      border-radius: 3px;
      text-decoration: none;
    }
    p {
      margin-bottom: 0;
    }
  }
  font-weight: bold;
  font-size: 18px;
  color: rgb(0, 0, 0);
`
const Message = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  color:#000;
  font-size: 17px;
  margin: 17px 0;
  a {
    color: rgb(104, 0, 146);
    font-weight: bold;
    border-radius: 3px;
    text-decoration: none;
  }
  p {
    margin-bottom: 0;
  }
`
const Information = styled.div`
  background-color: #C067F3;
  color:#fff;
  min-height: 45px;
  display: flex;
  align-items: center;
  font-size: 17px;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
`

const Action = styled.div`
  position: relative;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  vertical-align: top;
  text-align: center;
  touch-action: manipulation;
  background-color: rgb(255, 0, 151);
  background-image: none;
  font-size: 25px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 0px;
  color: rgb(255, 255, 255);
  height: 41px;
  line-height: 48px;
  font-family: Roboto, sans-serif;
  font-weight: bold;
  margin: 0px;
  outline: none;
  text-decoration: none;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 100px;
  padding: 0px 19px;
`

const replace = (text, context) => {
  try {
    if (text) {
      return Mustache.render(text, context)
    }
    return text
  } catch (err) {
    return text
  }
}

const context = {
  user: {
    username: 'nzk_ben'
  }
}

const MessagePreview = (props) => {
  const { text, title, author, actions } = props
  return <Wrapper>
    <Title>
      <Markdown source={replace(title, context)} />
    </Title>
    <Author icon={author && author.iconUrl}>
      <div />
      <div>{author && <Markdown source={author.name} />}</div>
    </Author>
    <Message>
      <Markdown source={replace(text, context)} />
    </Message>
    { actions.length > 0 && <Actions>
      { actions.map((a, i) => <Action key={i}>
        { a.name }
      </Action>)}  
    </Actions>}
    <Information>3 minutes ago</Information>
  </Wrapper>
}

MessagePreview.propTypes = {

}

MessagePreview.defaultProps = {

}

export default MessagePreview
