import styled from 'styled-components'
import { colors } from '../../theme'

export const Wrapper = styled.div`

`

export const Title = styled.div`
  color: ${colors.indigo};
  font-size: 21px;
  font-weight: bold;
  text-transform: uppercase;
  font-weight: bold;
`

export const Description = styled.div`
  font-style: italic;
  margin-bottom: 25px;
`